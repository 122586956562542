import styled from 'styled-components';
import media from 'utils/mediaStyle';
import { StepBox } from './StepRow';

export const Table = styled.table`
  width: 100%;
  box-sizing: content-box;
`;
export const TableHeader = styled.thead`
  border-bottom: 1px solid #dce0e9;
`;
export const TableBody = styled.tbody``;
export const TableCell = styled.td<any>`
  border-bottom-left-radius: ${({ isTotal }) => (isTotal ? '3pt' : '')};
  border-left: ${({ isTotal }) => (isTotal ? '1px' : '0')} solid #dce0e9;
  padding: 10px;
  ${media.sm`
    padding: 16pt 12pt;
  `};
`;
export const TableRow = styled.tr``;
export const TableFooter = styled.tfoot``;
export const UnderMOQ = styled.div`
  background: #ffe900;
  border-radius: 4px 4px 0px 0px;
  padding: 12px;
  margin-bottom: -4px;
`;

export default styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 6pt;
  border-radius: 3pt;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(4,15,15,0.12);
  ${media.sm`
    td {
      padding: 16px;
    }
  `}
  ${Table} {
    border: none;
    border-spacing: unset;
    border-bottom-right-radius: 4px;
  }
  ${TableCell} {
    border-left-color: #DCE0E9;
  }
  ${StepBox} {
    :first-child {
      border-top-left-radius: 3pt;
      border-top-right-radius: 3pt;
    }
    :last-child {
      border-top: none;
      border-bottom-left-radius: 3pt;
      border-bottom-right-radius: 3pt;
    }
  }
  ${StepBox} + ${StepBox} {
    border-top: none;
  }
  ${TableCell} {
    background-color: #ffffff;
  }
  ${TableFooter} {
    ${TableRow} ${TableCell} {
      :first-child {
        background-color: transparent;
        border-bottom: none;
        border-left: none;
      }
    }
  }

  ${TableRow} + ${TableRow} {
    border-top: 1px solid #DCE0E9;
  }
`;
