import { toCityId, toDistrictId } from 'components/LocationFormSection/messages';
import translations from 'translations';
import { Location, Maybe } from '../types/schema';
/**
 * Format address information
 * @param location is a Location type
 * @returns A new string with the 'address, district, city' order
 */

const formatAddress = (location?: Maybe<Location> | undefined): string => {
  if (!location) return '';
  const { city, address, district } = location;
  return `${address}, ${translations(toDistrictId(city, district))}, ${translations(toCityId(city))}`;
};

export default formatAddress;
