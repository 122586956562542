import React from 'react';
import moment from 'moment';

import Money from '../Money/Money';

import StepContainer from './StepContainer';
import StepItem from './StepItem';
import StepRow, { StepBox } from './StepRow';
import StepCol from './StepCol';
import Label from './Label';
import Value from './Value';
import Footer, { ButtonWrapper, FooterWrapper } from './Footer';

import Button from '../Button/Button';
import CartTotal from './CartTotal';
import OrderTable from './OrderTable';
import { FormattedMessage } from 'react-intl';
import utilsMessages from 'utils/messages';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import styled from 'styled-components';
import { Viewport } from 'components/ViewportProvider';
import { Cart, Store } from 'types/schema';
import formatAddress from 'utils/formatAddress';
import triggerGA4EcommerceEvent, { EventName } from 'utils/triggerGA4Event';
import { Modal } from 'antd';
import notAvailableProductImage from './not_available_product_image.svg';

const ButtonGoToCart = styled(Button)`
  padding: 8px auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 200px;
  height: 40px;
`;

const Step3StepCol = styled(StepCol)`
  flex-basis: 170px;
  gap: 20px;
  &.d-flex {
    display: flex;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.padding-bottom-5 {
    padding-bottom: 5px;
  }
  &.flex-column {
    flex-direction: column;
  }
  &.flex-basis-auto {
    flex-basis: auto;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const Image = styled.img``;

const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #242527;
  text-align: center;
`;

const Description = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #585a60;
  text-align: center;
`;

interface CheckoutStep3Props {
  checkout: any;
  loading: boolean;
  cart: Cart;
  onGoPreviousStep: () => void;
  onCheckout: () => void;
  orderDisabled: boolean;
  store: Store;
  openModalSomthingChanged: boolean;
  onGoToStep1: (step: number) => void;
}

export default ({
  checkout,
  cart,
  loading,
  onGoPreviousStep,
  onCheckout,
  orderDisabled,
  store,
  openModalSomthingChanged,
  onGoToStep1,
}: CheckoutStep3Props) => {
  const onCheckoutCompleted = () => {
    triggerGA4EcommerceEvent(EventName.ADD_PAYMENT_INFO, {
      ...cart.cartSet[0],
    });
    onCheckout();
  };
  const notIncludeAlcoholProduct = () => {
    return cart?.cartSet?.every((item) => {
      const isIncludesAlcoholProduct = item?.items?.some((it) => it?.product?.category?.parent?.name === 'Alcohol');
      return !isIncludesAlcoholProduct;
    });
  };
  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isMobileSize = width < 575;
        return (
          <StepContainer>
            {cart.cartSet.map((sup: any, index) => {
              return (
                <ErrorBoundary key={`step-3-${index}`}>
                  <StepItem>
                    <StepBox>
                      <StepRow>
                        {isMobileSize ? (
                          <Step3StepCol className="d-flex flex-column flex-basis-auto">
                            <div>
                              <Label>
                                <FormattedMessage {...utilsMessages.deliveryDate} />
                              </Label>
                              <Value>
                                {moment(sup.deliveryDatetime.start).format('HH:mm')} -{' '}
                                {moment(sup.deliveryDatetime.end).format('HH:mm')}
                              </Value>
                              <Value>{moment(sup.deliveryDatetime.start).format('DD/MM/YYYY')}</Value>
                            </div>
                            <div>
                              <Label>
                                <FormattedMessage {...utilsMessages.deliveryAddress} />
                              </Label>
                              <Value>{formatAddress(store.location)}</Value>
                            </div>
                            <div>
                              <Label>
                                <FormattedMessage {...utilsMessages.paymentMethod} />
                              </Label>
                              <Value>
                                <FormattedMessage {...utilsMessages[sup.paymentMethod]} />
                              </Value>
                            </div>
                          </Step3StepCol>
                        ) : (
                          <>
                            <Step3StepCol>
                              <Label>
                                <FormattedMessage {...utilsMessages.totalItem} />
                              </Label>
                              <Value>
                                {sup.items.length}
                                &nbsp;
                                <FormattedMessage {...utilsMessages.items} />
                              </Value>
                            </Step3StepCol>
                            <Step3StepCol>
                              <Label>
                                <FormattedMessage {...utilsMessages.deliveryTime} />
                              </Label>
                              <Value nowrap>
                                {moment(sup.deliveryDatetime.start).format('HH:mm')} -{' '}
                                {moment(sup.deliveryDatetime.end).format('HH:mm')} |{' '}
                                {moment(sup.deliveryDatetime.start).format('DD/MM/YYYY')}
                              </Value>
                            </Step3StepCol>
                            <Step3StepCol span={1}>
                              <Label>
                                <FormattedMessage {...utilsMessages.deliveryAddress} />
                              </Label>
                              <Value>{formatAddress(store.location)}</Value>
                            </Step3StepCol>
                            <Step3StepCol>
                              <Label>
                                <FormattedMessage {...utilsMessages.paymentMethod} />
                              </Label>
                              <Value>
                                {notIncludeAlcoholProduct() ? (
                                  <FormattedMessage {...utilsMessages[sup.paymentMethod]} />
                                ) : (
                                  <FormattedMessage {...utilsMessages.BANK_TRANSFER} />
                                )}
                              </Value>
                            </Step3StepCol>
                            <Step3StepCol>
                              <Label>
                                <FormattedMessage {...utilsMessages.purchased} />
                              </Label>
                              <Value>
                                <Money>{checkout.totalOfSupplier[sup.supplier.id]}</Money>
                              </Value>
                            </Step3StepCol>
                          </>
                        )}
                      </StepRow>
                    </StepBox>
                    {sup.deliveryNotes.length > 0 && (
                      <StepBox>
                        <StepRow>
                          <StepCol>
                            <Label>
                              <FormattedMessage {...utilsMessages.noteForDelivery} />
                            </Label>
                            {sup.deliveryNotes.map((n: string, index: number) => (
                              <Value key={index}>{n}</Value>
                            ))}
                          </StepCol>
                        </StepRow>
                      </StepBox>
                    )}
                    <OrderTable
                      name={`step-3-${index}`}
                      singleSupplierCart={sup}
                      productsQuantity={checkout.quantity}
                      disabled={true}
                    />
                  </StepItem>
                </ErrorBoundary>
              );
            })}
            <FooterWrapper>
              <Footer>
                <CartTotal>
                  <Value>
                    <FormattedMessage {...utilsMessages.cartTotal} />
                  </Value>
                  <div style={{ flex: 1 }} />
                  <Value>
                    <Money>{checkout.total}</Money>
                  </Value>
                </CartTotal>
                <ButtonWrapper>
                  <Button size="large" onClick={onGoPreviousStep}>
                    <FormattedMessage {...utilsMessages.back} />
                  </Button>
                  <div style={{ width: '6pt' }} />
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      onCheckoutCompleted();
                    }}
                    disabled={orderDisabled}
                    size="large"
                  >
                    <FormattedMessage {...utilsMessages.finish} />
                  </Button>
                </ButtonWrapper>
              </Footer>
            </FooterWrapper>
            {openModalSomthingChanged && (
              <Modal open={openModalSomthingChanged} closable={false} footer={false}>
                <ModalWrapper>
                  <Image src={notAvailableProductImage} alt={'not available products image'} loading="lazy" />
                  <div>
                    <Title>
                      <FormattedMessage {...utilsMessages.somethingChanged} />
                    </Title>
                    <Description>
                      <FormattedMessage {...utilsMessages.somethingChangedDescription} />
                    </Description>
                  </div>
                  <ButtonGoToCart
                    type="bg-primary"
                    onClick={() => {
                      onGoToStep1(1);
                    }}
                  >
                    <FormattedMessage {...utilsMessages.goToCart} />
                  </ButtonGoToCart>
                </ModalWrapper>
              </Modal>
            )}
          </StepContainer>
        );
      }}
    </Viewport.Consumer>
  );
};
