import styled from 'styled-components';

export const StepBox = styled.div<any>`
  border-bottom: 1px solid #dce0e9;
  padding: 16px;
  background-color: #fff;
  flex: 1;
  width: 100%;
`;

export default styled.div`
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  &.justify-content-between {
    justify-content: space-between;
  }
`;
